import { createI18n } from 'vue-i18n';
import {
  app,
  pages,
  inputs,
  buttons,
  components,
} from '@/settings/i18n/locales/pt-BR.json';

import bankPartnerInputs from '@/settings/i18n/locales/pt-BR/bankPartner/inputs.json';
import simulationInputs from '@/settings/i18n/locales/pt-BR/simulation/inputs.json';
import simulationPages from '@/settings/i18n/locales/pt-BR/simulation/pages.json';
import productInputs from '@/settings/i18n/locales/pt-BR/product/inputs.json';
import userInputs from '@/settings/i18n/locales/pt-BR/user/inputs.json';
const messages = {
  'pt-BR': {
    app,
    pages: {
      ...pages,
      simulation: simulationPages,
    },
    buttons,
    components,
    inputs: {
      ...inputs,
      product: productInputs,
      simulation: simulationInputs,
      bankPartner: bankPartnerInputs,
      products: productInputs,
      user: userInputs,
    },
  },
};

const i18n = createI18n({
  messages,
  locale: 'pt-BR',
  globalInjection: true,
  fallbackLocale: 'pt-BR',
  numberFormats: {
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
  },
  dateTimeFormats: {
    'pt-BR': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
      dateTime: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      },
    },
  },
});

export default i18n;
