import type { LoginDTO } from '@/interfaces/user/dto/login.dto';
import type { LoginResponseInterface } from '@/interfaces/user/responses/loginResponseInterface';
import type { PermissionInterface } from '@/interfaces/base/accessControl/permission.interface';
import type { Router } from 'vue-router';
import type { UserPermission } from '@/interfaces/user/permissionFilters.interface';
import { UserModel } from '@/models/user.model';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useCheckPermission } from '@/composables/useCheckPermission';

export const useUserStore = defineStore(
  'AtenaUserStore',
  () => {
    const userModel = new UserModel();
    /**
     * Informações sobre a autenticação do usuário
     */
    const accessData = ref<LoginResponseInterface['accessData'] | null>(null);

    /**
     * Informações sobre o perfil e preferências do usuário
     */
    const user = ref<LoginResponseInterface['user'] | null>(null);

    /**
     * Informações sobre a autenticação do usuário
     */
    const auth = computed(() => accessData.value);

    /**
     * Informações sobre o perfil e preferências do usuário
     */
    const profile = computed(() => user.value);

    /**
     * Tempo restante de expiração do token em minutos
     */
    const remainingTime = computed(() => {
      if (!accessData.value?.expiresIn || !accessData.value?.loginTime) {
        return {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }

      const now = new Date();
      const loginTime = new Date(accessData.value.loginTime);
      const expirationTime = addSeconds(loginTime, accessData.value.expiresIn);
      const totalSeconds = differenceInSeconds(expirationTime, now);
      const totalMinutes = Math.floor(totalSeconds / 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      const seconds = totalSeconds % 60;

      if (totalSeconds <= 0) {
        return {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }

      return {
        hours: Math.max(0, hours),
        minutes: Math.max(0, minutes),
        seconds: Math.max(0, seconds),
      };
    });

    const defaultFilterMode = computed<UserPermission>(() => {
      if (userHasPermissionTo('deal', 'viewAll')) {
        return 'all';
      } else if (userHasPermissionTo('deal', 'viewCompany')) {
        return 'company';
      } else if (userHasPermissionTo('deal', 'viewSubsidiary')) {
        return 'subsidiary';
      } else {
        return 'user';
      }
    });

    /**
     * Limpa as informações do usuário
     */
    const logout = async (router: Router) => {
      accessData.value = null;
      user.value = null;
      localStorage.removeItem('AtenaUserStore');
      await router.push({ name: 'Login' });
    };

    /**
     * Salva as informações de autenticação do usuário
     * @param response - resposta da requisição
     */
    const authenticate = (response: LoginResponseInterface) => {
      accessData.value = {
        accessToken: response.accessData.accessToken,
        expiresIn: response.accessData.expiresIn,
        refreshToken: response.accessData.refreshToken,
        loginTime: new Date().toISOString(),
      };

      user.value = {
        id: response.user.id,
        role: response.user.role,
        photo: response.user.photo,
        person: response.user.person,
        subsidiary: response.user.subsidiary,
      };
    };

    /**
     * Verifica se o usuário tem permissão para acessar um determinado recurso
     * @param domain
     * @param permission
     * @returns
     */
    const userHasPermissionTo = (
      domain: PermissionInterface['module'],
      permission: PermissionInterface['action'],
    ): boolean => {
      if (!profile.value || !profile.value.role?.permissions) return false;
      const permissions = profile.value.role.permissions;
      return useCheckPermission(permissions, domain, permission);
    };

    /**
     * Define os dados da subsidiary do usuário
     * @param payload dados da subsidiary
     * @returns
     */
    const setUserSubsidiary = (
      payload: LoginResponseInterface['user']['subsidiary'],
    ) => {
      if (!user.value) return;
      user.value.subsidiary = payload;
    };

    /**
     * Realiza o login do usuário
     * @param login
     */
    const login = async (login: LoginDTO) => {
      const response = await userModel.login(login);
      authenticate(response);
    };

    const refreshToken = async () => {
      if (!accessData.value?.refreshToken)
        throw new Error('Token de atualização não encontrado');
      const response = await userModel.refreshToken(
        accessData.value.refreshToken,
      );
      if (!user.value?.id) throw new Error('Usuário não encontrado');
      authenticate({
        user: { ...user.value! },
        accessData: {
          accessToken: response.authorization,
          loginTime: new Date().toISOString(),
          expiresIn: response.expiresIn,
          refreshToken: response.refreshToken,
        },
      });
    };

    return {
      auth,
      user,
      login,
      profile,
      logout,
      accessData,
      authenticate,
      refreshToken,
      remainingTime,
      setUserSubsidiary,
      defaultFilterMode,
      userHasPermissionTo,
    };
  },
  {
    persist: {
      key: 'AtenaUserStore',
      storage: localStorage,
    },
  },
);
