import type {
  DealStatusReason,
  DealDetailReasonDenied,
} from '@/interfaces/deal/dto/changeStatus.dto';

import type { BrazilianStates } from '@/interfaces/base/address/state.interface';
import type { CreateCommissionForm } from '@/components/commissions/CommissionForm.component.vue';
import type { DealAssetSegment } from '@/interfaces/deal/dealAssetSegment.interface';
import type { DealObjective } from '@/interfaces/components/deals/dealObjective.interface';
import type { DealOriginType } from '@/interfaces/deal/requestParameters.interface';
import type { ExpenseTypes } from '@/interfaces/product/expenseTypes.interface';
import type { GenderTypeInterface } from '@/interfaces/person/genderType.interface';
import type { MaritalStatus } from '@/interfaces/person/maritalStatus.interface';
import type { ProductIndexer } from '@/interfaces/product/productIndexer.interface';
import type { ProductInsurers } from '@/interfaces/product/productInsurers.interface';
import type { ProductSegments } from '@/interfaces/product/productSegments.interface';
import type { ProductTables } from '@/interfaces/product/productTables.interface';
import type { ProductTypes } from '@/interfaces/product/productTypes.interface';
import type { ProfessionalProfiles } from '@/interfaces/person/professionalProfiles.interface';
import type { RealtyTypes } from '@/interfaces/deal/assets/realtyTypes.interface';
import type { Region } from '@/interfaces/base/address/region.interface';
import type { SelectOption } from '@/interfaces/base/select/option';
import type { VolumeOfOperations } from '@/interfaces/subscribe/volumeOfOperations.interface';
import type { ContactType } from '@/interfaces/base/contact/contactType.enum';
import type { DealAssociateType } from '@/interfaces/deal/dealAssociate.interface';

/**
 * Opções para o select do tipo de imóveis do negócio
 */
const realtyAssetOptions: Array<SelectOption<RealtyTypes>> = [
  { value: 'casa', label: 'Casa' },
  { value: 'sala', label: 'Sala' },
  { value: 'galpao', label: 'Galpão' },
  { value: 'fabrica', label: 'Fábrica' },
  { value: 'apartamento', label: 'Apartamento' },
  { value: 'terreno', label: 'Terreno em rua pública' },
  { value: 'terreno_em_condominio', label: 'Terreno em loteamento' },
  { value: 'outros', label: 'Outros' },
];

/**
 * Opções para o select do tipo de contato
 */
const contactTypeOptions: Array<SelectOption<ContactType>> = [
  { value: 'cellphone', label: 'WhatsApp' },
  { value: 'email', label: 'E-mail' },
  { value: 'site', label: 'Site' },
  { value: 'phone', label: 'Telefone Fixo' },
];

/**
 * Opções para o select do motivo do negócio declinado
 */
const statusReasonOptions: Array<SelectOption<DealStatusReason>> = [
  { value: 'no_interest', label: 'O cliente não tem interesse' },
  { value: 'no_contact', label: 'Não foi possível entrar em contato' },
  {
    value: 'no_profile',
    label: 'O cliente não tem perfil de crédito compatível',
  },
];

const transactionStatus: Array<
  SelectOption<CreateCommissionForm['transactions'][number]['status']>
> = [
  { value: 'PAID', label: 'Paga' },
  { value: 'PENDING', label: 'Pendente' },
  { value: 'CANCELED', label: 'Cancelada' },
];

const commissionStatus: Array<SelectOption<CreateCommissionForm['status']>> = [
  { value: 'PAID', label: 'Paga' },
  { value: 'CANCELED', label: 'Cancelada' },
  { value: 'PREVISION', label: 'Previsão' },
  { value: 'PENDING_INVOICE', label: 'NF pendente' },
];

const dealAssociateOptions: Array<SelectOption<DealAssociateType>> = [
  { value: 'CUSTOMER', label: 'Cliente' },
  { value: 'SELLER', label: 'Vendedor' },
];

/**
 * Opções para o select do detalhe do motivo do negócio declinado
 */
const detailsOfReasonDeniedOptions: Array<
  SelectOption<DealDetailReasonDenied>
> = [
  {
    value: 'best_proposal_in_the_competition',
    label: 'Obteve uma cotação melhor do que a proposta ofertada',
  },
  { value: 'blocked_contact', label: 'O cliente bloqueou o número de contato' },
  {
    value: 'denied_due_to_financial_profile',
    label: 'Problemas de crédito',
  },
  {
    value: 'denied_due_to_restrictive_impediments',
    label: 'Restrições no CPF/CNPJ',
  },
  {
    value: 'did_not_return_contact',
    label: 'Parou de responder o contato',
  },
  {
    value: 'doesnt_comply_policy',
    label: 'Não aceitou as políticas de crédito',
  },
  {
    value: 'insufficient_or_unproven_income',
    label: 'Renda insuficiente ou não comprovada',
  },
  {
    value: 'invalid_contact',
    label: 'Contatos inválidos',
  },
  {
    value: 'legal_risks',
    label: 'Riscos legais',
  },
  {
    value: 'no_interest',
    label: 'Não está interessado no produto',
  },
  {
    value: 'not_interested_credit',
    label: 'Não está interessado em crédito',
  },
  {
    value: 'outside_of_trading_time',
    label: 'Negócio fora do período comercial',
  },
  {
    value: 'real_estate_warranty_denied',
    label: 'Imóvel não aceito como garantia',
  },
  {
    value: 'restrictive_or_impeding_actions',
    label: 'Restrições legais',
  },
  {
    value: 'sold_property',
    label: 'Imóvel já vendido',
  },
  {
    value: 'someone_elses_phone',
    label: 'Contato não pertence ao cliente',
  },
];

/**
 * Opções para o select de objetivo do negócio
 * @param assetSegment Segmento do negócio
 * @returns
 */
export const creditObjectiveOptions = (
  assetSegment: DealAssetSegment,
): Array<SelectOption<DealObjective>> => {
  return [
    {
      value: 'comprar',
      label: assetSegment === 'Realty' ? 'Comprar imóvel' : 'Comprar veículo',
    },
    { value: 'investir', label: 'Investir' },
    { value: 'capital_de_giro', label: 'Capital de giro' },
    { value: 'quitar_dividas', label: 'Quitar dívidas' },
    { value: 'outros', label: 'Outros' },
  ];
};

/**
 * Opções para o select de origem do negócio
 */
export const dealOriginOptions: Array<SelectOption<DealOriginType>> = [
  { label: 'Bext', value: 'bext' },
  { label: 'Simulador', value: 'simulator' },
  { label: 'Cadastro Público', value: 'public' },
  { label: 'Cadastro Interno', value: 'internal' },
];

type SelectDeadlineObject = Record<
  DealAssetSegment,
  Record<ProductTypes, Array<SelectOption<number>>>
>;

export const selectDeadlineOptions: SelectDeadlineObject = {
  Realty: {
    BestCar: [],
    Financing: [
      {
        value: -1,
        label: 'Prazo personalizado',
      },
      {
        value: 60,
        label: '60 meses',
      },
      {
        value: 120,
        label: '120 meses',
      },
      {
        value: 180,
        label: '180 meses',
      },
      {
        value: 240,
        label: '240 meses',
      },
      {
        value: 360,
        label: '360 meses',
      },
      {
        value: 420,
        label: '420 meses',
      },
    ],
    SimpleBest: [],
    HomeEquity: [
      {
        value: -1,
        label: 'Prazo personalizado',
      },
      {
        value: 60,
        label: '60 meses',
      },
      {
        value: 120,
        label: '120 meses',
      },
      {
        value: 180,
        label: '180 meses',
      },
      {
        value: 240,
        label: '240 meses',
      },
    ],
  },
  Vehicle: {
    BestCar: [
      {
        value: -1,
        label: 'Prazo personalizado',
      },
      {
        value: 12,
        label: '12 meses',
      },
      {
        value: 24,
        label: '24 meses',
      },
      {
        value: 36,
        label: '36 meses',
      },
      {
        value: 48,
        label: '48 meses',
      },
      {
        value: 60,
        label: '60 meses',
      },
    ],
    Financing: [
      {
        value: -1,
        label: 'Prazo personalizado',
      },
      {
        value: 12,
        label: '12 meses',
      },
      {
        value: 24,
        label: '24 meses',
      },
      {
        value: 36,
        label: '36 meses',
      },
      {
        value: 48,
        label: '48 meses',
      },
      {
        value: 60,
        label: '60 meses',
      },
    ],
    SimpleBest: [],
    HomeEquity: [],
  },
};

const systemConstants = {
  dir: 'Bext',
  /**
   * Nome do projeto
   */
  name: 'BextPro',
  /**
   * Nome do webApp
   */
  nameWebapp: 'BextPro',
  /**
   * Página institucional
   */
  homePage: 'https://bext.vc',
  totalNumberProcessSteps: 7,
  selectOptionsMaritalStatus: [
    {
      label: 'Solteiro(a)',
      value: 'solteiro',
    },
    {
      label: 'Casado(a)',
      value: 'casado',
    },
    {
      label: 'União Estável',
      value: 'uniao_estavel',
    },
    {
      label: 'Separado(a)',
      value: 'separado',
    },
    {
      label: 'Desquitado(a)',
      value: 'desquitado',
    },
    {
      label: 'Viúvo(a)',
      value: 'viuvo',
    },
  ] as Array<SelectOption<MaritalStatus>>,
  genderList: [
    { value: 'feminino', label: 'Feminino' },
    { value: 'masculino', label: 'Masculino' },
    { value: 'nao_informado', label: 'Prefiro não dizer' },
    { value: 'outros', label: 'Outros' },
  ] as Array<SelectOption<GenderTypeInterface>>,
  selectProfessionalProfileOption: [
    {
      label: 'Assalariado',
      value: 'assalariado',
    },
    {
      label: 'Aposentado',
      value: 'aposentado',
    },
    {
      label: 'Pensionista',
      value: 'pensionista',
    },
    {
      label: 'Autônomo',
      value: 'autonomo',
    },
    {
      label: 'Empresário',
      value: 'empresario',
    },
    {
      label: 'Profissional Liberal',
      value: 'profissional_liberal',
    },
    {
      label: 'Funcionário Público',
      value: 'funcionario_publico',
    },
  ] as Array<SelectOption<ProfessionalProfiles>>,
  stateOptions: [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ] as Array<SelectOption<BrazilianStates>>,
  regionsOptions: [
    { value: 'centroOeste', label: 'Centro oeste' },
    { value: 'nordeste', label: 'Nordeste' },
    { value: 'norte', label: 'Norte' },
    { value: 'sudeste', label: 'Sudeste' },
    { value: 'sul', label: 'Sul' },
  ] as Array<SelectOption<Region>>,
  regionsByUf: {
    AC: 'norte',
    AL: 'nordeste',
    AP: 'norte',
    AM: 'norte',
    BA: 'nordeste',
    CE: 'nordeste',
    DF: 'centroOeste',
    ES: 'sudeste',
    GO: 'centroOeste',
    MA: 'nordeste',
    MT: 'centroOeste',
    MS: 'centroOeste',
    MG: 'sudeste',
    PA: 'norte',
    PB: 'nordeste',
    PR: 'sul',
    PE: 'nordeste',
    PI: 'nordeste',
    RJ: 'sudeste',
    RN: 'nordeste',
    RS: 'sul',
    RO: 'norte',
    RR: 'norte',
    SC: 'sul',
    SP: 'sudeste',
    SE: 'nordeste',
    TO: 'norte',
  } as Record<BrazilianStates, Region>,

  selectSegmentProductOptions: [
    {
      value: 'Vehicular',
      label: 'Veicular',
    },
    {
      value: 'Real State',
      label: 'Imobiliário',
    },
  ] as Array<SelectOption<ProductSegments>>,

  selectIndexerProductOptions: [
    {
      value: 'TR(Pós)',
      label: 'TR - Pós Fixada',
    },
    {
      value: 'CDI(Pós)',
      label: 'CDI - Pós Fixada',
    },
    {
      value: 'Fixa',
      label: 'Fixa',
    },
    {
      value: 'IPCA(Pós)',
      label: 'IPCA - Pós Fixada',
    },
  ] as Array<SelectOption<ProductIndexer>>,
  selectInsurerProductOptions: [
    {
      value: 'default',
      label: 'Padrão',
    },
    {
      value: 'itau',
      label: 'Itaú',
    },
    {
      value: 'zurich',
      label: 'Zurich',
    },
    {
      value: 'mapfre',
      label: 'Mapfre',
    },
  ] as Array<SelectOption<ProductInsurers>>,
  selectTableProductOptions: [
    {
      value: 'PRICE',
      label: 'PRICE',
    },
    {
      value: 'SAC',
      label: 'SAC',
    },
  ] as Array<SelectOption<ProductTables>>,
  selectVolumeOfOperations: [
    {
      value: 'none',
      label: 'Não houve volume',
    },
    {
      value: 'low',
      label: 'Menor que R$ 500.000,00',
    },
    {
      value: 'medium',
      label: 'Entre R$ 500.000,00 e R$ 1.000.000,00',
    },
    {
      value: 'high',
      label: 'Entre R$ 1.000.000,00 e R$ 2.000.000,00',
    },
    {
      value: 'veryHigh',
      label: 'Maior que R$ 2.000.000,00',
    },
  ] as Array<SelectOption<VolumeOfOperations>>,
  selectTypeProductOptions: [
    {
      value: 'Financing',
      label: 'Financiamento',
    },
    {
      value: 'HomeEquity',
      label: 'Crédito com garantia',
    },
  ] as Array<SelectOption<ProductTypes>>,

  selectDealAssetSegmentOptions: [
    {
      value: 'Realty',
      label: 'Imobiliário',
    },
    {
      value: 'Vehicle',
      label: 'Veicular',
    },
  ] as Array<SelectOption<DealAssetSegment>>,
  selectTypeRealEstateSimulationOperation: [
    {
      value: 'Financing',
      label: 'Financiamento',
    },
    {
      value: 'HomeEquity',
      label: 'Crédito com garantia',
    },
  ] as Array<SelectOption<ProductTypes>>,
  selectTypeRealEstateSimulationOperationBest: [
    {
      value: 'Financing',
      label: 'Financiamento',
    },
    {
      value: 'HomeEquity',
      label: 'Crédito com garantia',
    },
  ] as Array<SelectOption<ProductTypes>>,
  selectTypeVehicleSimulationOperation: [
    {
      value: 'Financing',
      label: 'Financiamento',
    },
    {
      value: 'BestCar',
      label: 'Crédito com garantia',
    },
  ] as Array<SelectOption<ProductTypes>>,
  vehicleTypesOptions: [
    {
      value: '1',
      label: 'Veículo leve',
    },
    {
      value: '3',
      label: 'Veículo pesado',
    },
  ] as Array<SelectOption<string>>,
  /**
   * @deprecated use selectDeadlineOptions
   */
  selectDeadlineVehicle: [
    {
      value: -1,
      label: 'Prazo personalizado',
    },
    {
      value: 12,
      label: '12 meses',
    },
    {
      value: 24,
      label: '24 meses',
    },
    {
      value: 36,
      label: '36 meses',
    },
    {
      value: 48,
      label: '48 meses',
    },
    {
      value: 60,
      label: '60 meses',
    },
  ] as Array<SelectOption<number>>,
  /**
   * @deprecated use selectDeadlineOptions
   */
  selectDeadlinesSimpleSimulationOptions: [
    {
      value: -1,
      label: 'Prazo personalizado',
    },
    {
      value: 60,
      label: '60 meses',
    },
    {
      value: 120,
      label: '120 meses',
    },
    {
      value: 180,
      label: '180 meses',
    },
    {
      value: 240,
      label: '240 meses',
    },
    {
      value: 360,
      label: '360 meses',
    },
    {
      value: 420,
      label: '420 meses',
    },
  ] as Array<SelectOption<number>>,
  /**
   * @deprecated use selectDeadlineOptions
   */
  selectDeadlinesSimpleSimulationOptionsCashBest: [
    {
      value: -1,
      label: 'Prazo personalizado',
    },
    {
      value: 60,
      label: '60 meses',
    },
    {
      value: 120,
      label: '120 meses',
    },
    {
      value: 180,
      label: '180 meses',
    },
    {
      value: 240,
      label: '240 meses',
    },
  ] as Array<SelectOption<number>>,

  information: {
    socialMedia: {
      telegram: 'https://t.me/bextnews',
      youtube: 'https://www.youtube.com/@bextplay',
      facebook: 'https://www.facebook.com/bext.vc',
      instagram: 'https://www.instagram.com/bext.vc',
      linkedin: 'https://www.linkedin.com/company/bextvc',
    },
  },
  deals: {
    columns: {
      Realty: [
        {
          index: 0,
          name: 'Novos Leads',
          color: '#FF598F',
          status: 'newLead',
        },
        {
          index: 1,
          name: 'Cadastro',
          color: '#FF595E',
          status: 'registration',
        },
        {
          index: 2,
          name: 'Negociação',
          color: '#FF645C',
          status: 'negotiation',
        },
        {
          index: 3,
          name: 'Fechamento de pasta',
          color: '#ff6f59',
          status: 'folderTreatment',
        },
        {
          index: 4,
          name: 'Envio de proposta',
          color: '#FF7043',
          status: 'preAnalysis',
        },
        {
          index: 5,
          name: 'Análise de crédito',
          color: '#FF9933',
          status: 'creditAnalysis',
        },
        {
          index: 6,
          name: 'Aprovado',
          color: '#FFCA3A',
          status: 'approved',
        },
        {
          index: 8,
          name: 'Formalização',
          color: '#3F51F5',
          status: 'legalReview',
        },
        {
          index: 9,
          name: 'Contrato',
          color: '#00ACC1',
          status: 'contractIssuance',
        },
        {
          index: 10,
          name: 'Registro do imóvel',
          color: '#4CAF50',
          status: 'propertyRegistry',
        },
        {
          index: 11,
          name: 'Liberação de crédito',
          color: '#8AC926',
          status: 'completed',
        },
        {
          index: 12,
          name: 'Negado / Declinado',
          color: '#F00F00',
          status: 'denied',
        },
      ],
      Vehicle: [
        {
          index: 0,
          name: 'Cadastro',
          color: '#FF595E',
          status: 'registration',
        },
        {
          index: 1,
          name: 'Fechamento de pasta',
          color: '#ff6f59',
          status: 'folderTreatment',
        },
        {
          index: 2,
          name: 'Análise',
          color: '#FF7043',
          status: 'analysis',
        },
        {
          index: 3,
          name: 'Aprovação',
          color: '#FF9933',
          status: 'approved',
        },
        {
          index: 4,
          name: 'Vistoria',
          color: '#FFCA3A',
          status: 'inspection',
        },
        {
          index: 5,
          name: 'Formalização',
          color: '#3F51B5',
          status: 'formalization',
        },
        {
          index: 6,
          name: 'Contrato',
          color: '#00ACC1',
          status: 'contract',
        },
        {
          index: 7,
          name: 'Concluído',
          color: '#4CAF50',
          status: 'completed',
        },
        {
          index: 8,
          name: 'Negado / Declinado',
          color: '#F00F00',
          status: 'denied',
        },
      ],
    } as Record<
      DealAssetSegment,
      Array<{
        index: number;
        name: string;
        color: string;
        status: string;
      }>
    >,
    checklists: {
      realty: [
        {
          fileName: 'bextPro_housePhoIPTUFiletoFiles',
          description: 'IPTU Imóvel',
        },
        {
          fileName: 'bextPro_fullDeedFile',
          description: 'Matrícula completa do imóvel',
        },
        {
          fileName: 'bextPro_housePhotoFiles',
          description: 'Fotos do imóvel',
        },
      ],
      vehicle: [
        {
          fileName: 'bextPro_driverLicense',
          description: 'Carteira Nacional de Habilitação (CNH)',
        },
        {
          fileName: 'bextPro_vehicleTransferenceAuthorization',
          description: 'DUT',
        },
      ],
      professionalProfile: {
        assalariado: [
          {
            fileName: 'bextPro_identityDocumentFile',
            description: 'RG, CPF ou CNH',
          },
          {
            fileName: 'bextPro_addressDocumentVerificationFile',
            description:
              'Comprovante de Residência atual em nome, últimos 60 dias',
          },
          {
            fileName: 'bextPro_civilRecordFile',
            description: 'Certidão Civil (nascimento ou casamento)',
          },
          {
            fileName: 'bextPro_completeTaxDeclarationFile',
            description:
              'IRPF Completo - Recibo e Declaração do último exercício',
          },
          {
            fileName: 'bextPro_recentPayslipsFile',
            description: '03 últimos holerites / folha de pagamento',
          },
          {
            fileName: 'bextPro_personalBankStatementFile',
            description: '03 últimos Extratos Bancários PF Completos',
          },
          {
            fileName: 'bextPro_jobRecordFile',
            description:
              'CTPS Completa (página da foto - dados cadastrais e registro)',
          },
        ],
        pensionista: [
          {
            fileName: 'bextPro_identityDocumentFile',
            description: 'RG, CPF ou CNH',
          },
          {
            fileName: 'bextPro_addressDocumentVerificationFile',
            description:
              'Comprovante de Residência atual em nome, últimos 60 dias',
          },
          {
            fileName: 'bextPro_civilRecordFile',
            description: 'Certidão Civil (nascimento ou casamento)',
          },
          {
            fileName: 'bextPro_completeTaxDeclarationFile',
            description:
              'IRPF Completo - Recibo e Declaração do último exercício',
          },
          {
            fileName: 'bextPro_recentPayslipsFile',
            description: '03 últimos holerites / folha de pagamento',
          },
          {
            fileName: 'bextPro_personalBankStatementFile',
            description: '03 últimos Extratos Bancários PF Completos',
          },
          {
            fileName: 'bextPro_jobRecordFile',
            description:
              'CTPS Completa (página da foto - dados cadastrais e registro)',
          },
        ],
        aposentado: [
          {
            fileName: 'bextPro_identityDocumentFile',
            description: 'RG, CPF ou CNH',
          },
          {
            fileName: 'bextPro_addressDocumentVerificationFile',
            description:
              'Comprovante de Residência atual em nome, últimos 60 dias',
          },
          {
            fileName: 'bextPro_civilRecordFile',
            description: 'Certidão Civil (nascimento ou casamento)',
          },
          {
            fileName: 'bextPro_completeTaxDeclarationFile',
            description:
              'IRPF Completo - Recibo e Declaração do último exercício',
          },
          {
            fileName: 'recentCreditDetailsFile',
            description: '03 Últimos detalhamentos de crédito',
          },
          {
            fileName: 'bextPro_personalBankStatementFile',
            description: '03 Últimos Extratos Bancários PF Completos',
          },
        ],
        autonomo: [
          {
            fileName: 'bextPro_identityDocumentFile',
            description: 'RG, CPF ou CNH',
          },
          {
            fileName: 'bextPro_addressDocumentVerificationFile',
            description:
              'Comprovante de residência atual em nome, últimos 60 dias',
          },
          {
            fileName: 'bextPro_civilRecordFile',
            description: 'Certidão Civil (nascimento ou casamento)',
          },
          {
            fileName: 'bextPro_completeTaxDeclarationFile',
            description:
              'IRPF Completo - Recibo e Declaração do último exercício',
          },
          {
            fileName: 'bextPro_recentPayslipsFile',
            description: '06 Últimos extratos bancários PF Completos',
          },
          {
            fileName: 'handwrittenLetter',
            description:
              'Contratos de prestação de serviço ou documentos que comprovem sua atividade.',
          },
        ],
        empresario: [
          {
            fileName: 'bextPro_identityDocumentFile',
            description: 'RG, CPF ou CNH',
          },
          {
            fileName: 'bextPro_addressDocumentVerificationFile',
            description:
              'Comprovante de Residência atual em nome, últimos 60 dias',
          },
          {
            fileName: 'bextPro_civilRecordFile',
            description: 'Certidão Civil (nascimento ou casamento)',
          },
          {
            fileName: 'bextPro_completeTaxDeclarationFile',
            description:
              'IRPF Completo - Recibo e Declaração do último exercício',
          },
          {
            fileName: 'bextPro_lastSixPayslipsFile',
            description: '06 últimos Extratos Bancários PF Completos',
          },
          {
            fileName: 'bextPro_corporateBylawsFile',
            description:
              'Contrato social / última alteração social ou Requerimento do Empresário',
          },
          {
            fileName: 'bextPro_annualRevenueReportFile',
            description:
              'Faturamento da Empresa dos últimos 12 meses constando mês de xxx, assinado e carimbado pelo contador',
          },
          {
            fileName: 'bextPro_taxFilingLastYearFile',
            description:
              'IRPJ / DEFIS / ECF Completo - recibo e declaração do último exercício',
          },
          {
            fileName: 'bextPro_corporateBankStatementsFile',
            description: 'Extratos Bancários PJ Completos',
          },
        ],
        profissional_liberal: [
          {
            fileName: 'bextPro_identityDocumentFile',
            description: 'RG, CPF ou CNH',
          },
          {
            fileName: 'bextPro_addressDocumentVerificationFile',
            description:
              'Comprovante de Residência atual em nome, últimos 60 dias',
          },
          {
            fileName: 'bextPro_civilRecordFile',
            description: 'Certidão Civil (nascimento ou casamento)',
          },
          {
            fileName: 'bextPro_completeTaxDeclarationFile',
            description:
              'IRPF Completo - Recibo e Declaração do último exercício',
          },
          {
            fileName: 'bextPro_bankDocuments',
            description: '06 últimos Extratos Bancários PF Completos',
          },
          {
            fileName: 'bextPro_serviceAgreementFile',
            description: 'Contrato prestação de serviço - se houver',
          },
          {
            fileName: 'bextPro_workLicenseFile',
            description: 'Carteira Profissional',
          },
        ],
        funcionario_publico: [
          {
            fileName: 'bextPro_identityDocumentFile',
            description: 'RG, CPF ou CNH',
          },
          {
            fileName: 'bextPro_addressDocumentVerificationFile',
            description:
              'Comprovante de residência atual em nome, últimos 60 dias',
          },
          {
            fileName: 'bextPro_civilRecordFile',
            description: 'Certidão Civil (nascimento ou casamento)',
          },
          {
            fileName: 'bextPro_completeTaxDeclarationFile',
            description:
              'IRPF Completo - Recibo e Declaração do último exercício',
          },
          {
            fileName: 'bextPro_recentPayslipsFile',
            description: '03 últimos contra - cheque - folha de pagamento',
          },
          {
            fileName: 'bextPro_personalBankStatementFile',
            description: '03 últimos Extratos Bancários PF Completos',
          },
          {
            fileName: 'bextPro_officialAppointmentRecordFile',
            description:
              'Portaria ou nomeação onde consta data de admissão (caso não houver no holerite)',
          },
        ],
      },
    },
  },
  inputMasks: {
    monetary: {
      mask: [
        'R$ #',
        'R$ ##',
        'R$ ,##',
        'R$ #,##',
        'R$ ##,##',
        'R$ ###,##',
        'R$ .###,##',
        'R$ #.###,##',
        'R$ ##.###,##',
        'R$ ###.###,##',
        'R$ .###.###,##',
        'R$ #.###.###,##',
        'R$ ##.###.###,##',
        'R$ ###.###.###,##',
        'R$ .###.###.###,##',
        'R$ #.###.###.###,##',
        'R$ ##.###.###.###,##',
        'R$ ###.###.###.###,##',
      ],
      reversed: true,
      eager: true,
      tokens: {
        '0': { pattern: /\d/, optional: true },
        '9': { pattern: /\d/, repeated: true },
      },
    },
    numeric: {
      integer: {
        mask: [
          '#',
          '##',
          '###',
          '.###',
          '#.###',
          '##.###',
          '###.###',
          '.###.###',
          '#.###.###',
          '##.###.###',
          '###.###.###',
          '.###.###.###',
          '#.###.###.###',
          '##.###.###.###',
          '###.###.###.###',
          '.###.###.###.###',
          '#.###.###.###.###',
          '##.###.###.###.###',
          '###.###.###.###.###',
        ],
        reversed: true,
        eager: true,
        tokens: {
          '0': { pattern: /\d/, optional: true },
          '9': { pattern: /\d/, repeated: true },
        },
      },
      decimal: {
        mask: [
          '#',
          '##',
          ',##',
          '#,##',
          '##,##',
          '###,##',
          '.###,##',
          '#.###,##',
          '##.###,##',
          '###.###,##',
          '.###.###,##',
          '#.###.###,##',
          '##.###.###,##',
          '###.###.###,##',
          '.###.###.###,##',
          '#.###.###.###,##',
          '##.###.###.###,##',
          '###.###.###.###,##',
        ],
        reversed: true,
        eager: true,
        tokens: {
          '0': { pattern: /\d/, optional: true },
          '9': { pattern: /\d/, repeated: true },
        },
      },
    },
    phone: {
      mask: ['(##) 0####-####'],
      reversed: false,
      eager: true,
      tokens: {
        '0': { pattern: /\d/, optional: true },
      },
    },
    document: {
      mask: ['###.###.###-##', '##.###.###/####-##'],
      reversed: false,
      eager: false,
    },
    personDocument: {
      mask: ['###.###.###-##'],
      reversed: false,
      eager: false,
    },
    date: {
      mask: ['##/##/####'],
      reversed: false,
      eager: false,
    },
    companyDocument: {
      mask: ['##.###.###/####-##'],
      reversed: false,
      eager: false,
    },
    postalCode: {
      mask: ['#####-###'],
      reversed: false,
      eager: false,
    },
    creditCard: {
      number: {
        mask: ['#### #### #### ####'],
        reversed: false,
        eager: false,
      },
      expirationDate: {
        mask: ['##/##'],
        reversed: false,
        eager: false,
      },
    },
    percentage: {
      mask: ['0000#.0#%', '0000#.##%', '0000#.0000##%'],
      reversed: true,
      eager: false,
      tokens: {
        0: {
          pattern: /[0-9]/,
          optional: true,
        },
      },
    },
  },
  expenseTypeSelectOptions: [
    {
      value: 'fixed',
      label: 'Fixa (R$)',
    },
    {
      value: 'percentage',
      label: 'Porcentagem (%)',
    },
  ] as Array<SelectOption<ExpenseTypes>>,
  commissionStatus,
  transactionStatus,
  dealOriginOptions,
  contactTypeOptions,
  realtyAssetOptions,
  statusReasonOptions,
  dealAssociateOptions,
  selectDeadlineOptions,
  creditObjectiveOptions,
  detailsOfReasonDeniedOptions,
};

export default systemConstants;
