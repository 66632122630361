<template>
  <div class="min-w-screen relative z-0 flex min-h-screen w-full items-center">
    <CookieBanner
      v-if="showCookieBanner === true"
      :class="[showCookieBanner ? 'opacity-100' : 'hidden opacity-0']"
      class="z-50 transition-opacity"
      @show-cookie-banner="showCookieBanner = $event" />
    <Toaster />

    <Suspense>
      <RouterView class="z-0" />
    </Suspense>
  </div>
</template>

<script lang="ts">
  import CookieBanner from '@/components/base/cookieBanner.component.vue';
  import Toaster from '@/components/ui/toast/Toaster.vue';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'App',
    components: {
      Toaster,
      CookieBanner,
    },

    data() {
      return {
        utms: '',
        isLoading: true,
        showCookieBanner: true,
      };
    },

    mounted() {
      if (this.$route.fullPath.includes('?')) {
        var fullUrl = this.$route.fullPath.split('?');
        this.$root.utms = '?' + fullUrl[1];
      }
      this.showCookieConsentBanner();
      const appDiv = document.getElementById('app');
      appDiv?.classList.add('loaded');
    },
    methods: {
      getCookie(nomeDoCookie: string) {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
          const [cookieName, cookieValue] = cookie.split('=');
          if (cookieName === nomeDoCookie) {
            return cookieValue;
          }
        }
        return '';
      },
      showCookieConsentBanner() {
        const showCookiePreferencesBanner = this.getCookie(
          'bextPro_showCookieBanner',
        );

        if (showCookiePreferencesBanner.length) {
          if (showCookiePreferencesBanner === 'false') {
            this.showCookieBanner = false;
          } else {
            this.showCookieBanner = true;
          }
        } else {
          this.showCookieBanner = true;
        }
      },
    },
  });
</script>
